<template>
    <SalesOrdersTable />
    
</template>

<script>
// @ is an alias to /src
import SalesOrdersTable from '../components/SalesOrdersTable';

export default {
  name: 'SalesOrders',
  components: {
    SalesOrdersTable,
  },
}
</script>