//This JS file is accessed in the Vuex state block
//It is then passed as a function to the parent component 
//Values are then assigned to component propss

//----------------------------------------------
//BUTTONS DATA
//Primary button data
const primaryCTA = {
    btn_primary_color: '#33cc99',
    btn_plus_icon: 'mdi-plus',
    btn_ml_10: 'ml-10',
}
//Secondary button data
const secondaryCTA = {
    btn_secondary_color: '#e7e7f7',
    btn_ml_10: 'ml-10',
    btn_view_icon: 'mdi-eye',
}
//Add sales order button data
const addSalesOrderBtn = {
    btn_add_sales_order: 'New Order',
    btn_new_sales_order_to: '/new-sales-order',
}

//----------------------------------------------
//DATA TABLE HEADERS
//Sales orders table
const salesOrderTableHeaders = [
    { text: 'Order #', sortable: true, value: 'order_number', align: 'start' },
    { text: 'Date Created', value: 'order_date' },
    { text: 'Customer Name', value: 'customer' },
    { text: 'Branch', value: 'order_branch.branch_name' },
    { text: 'Total', value: 'order_value', align: 'right' },
    { text: 'Payment Status', value: 'full_payment_received' },
    { text: 'Production Status', value: 'production_status' },
    { text: 'Invoiced', value: 'xeroInvoiceId' },
    { text: 'Actions', value: 'order_id' },
    { text: '', value: 'quote_ref', align: ' d-none' }, //Ensures we still have search functionality
    { text: '', value: 'customer_name', align: ' d-none' }, //Ensures we still have search functionality
]
//----------------------------------------------
//FORM HEADERS
//Add Sales Order Form Header
const newSalesOrderFormHeader = {
    form_header_title: 'Add New Sales Order',
    form_header_to: '/sales-orders',
}
//Edit Sales Order Form Header
const editSalesOrderFormHeader = {
    form_header_title: 'Edit Sales Order',
    form_header_to: '/sales-orders',
}
//Sales Order Summary Form Header
const viewSalesOrderFormHeader = {
    form_header_title: 'Sales Order Summary',
    form_header_to: '/sales-orders',
}

//EXPORTED to the Vuex state block
export {
    //BUTTONS
    primaryCTA, secondaryCTA, addSalesOrderBtn,

    //DATA TABLE HEADERS
    salesOrderTableHeaders,

    //FORM HEADERS
    newSalesOrderFormHeader, viewSalesOrderFormHeader, editSalesOrderFormHeader
}