//Initialise form values on New & Edit Sales Order views
const mixin_InitSalesOrder = {
    data() {
        return {
            delivery_status_items: [
                { text: 'Not Started', value: 'Not Started', disabled: false },
                { text: 'Delivery Note Created', value: 'Delivery Note Created', disabled: false },
                { text: 'Ready For Dispatch', value: 'Ready For Dispatch', disabled: false },
                { text: 'Dispatched', value: 'Dispatched', disabled: false },
                { text: 'Completed', value: 'Completed', disabled: false }
            ],
            order_status_items: [
                { text: 'Quote', value: 'Quote', disabled: false },
                { text: 'Confirmed', value: 'Confirmed', disabled: false },
                { text: 'Completed', value: 'Completed', disabled: false },
                { text: 'Cancelled', value: 'Cancelled', disabled: false }
            ],
            //Array populated by customer names from firebase
            customers: [],
            customer: null,
            //Initially set all database values to null
            order_id: null,
            order_number: null,
            //Defaults to today's date
            order_date: new Date().toISOString().slice(0, 10),
            customer_name: null,
            date: null,
            menu: null,
            //Line item related data
            hover: false,
            deleteDisable: true,
            //Products array is populated from database
            products: [],
            //Order & quote number counters
            order_count: null,
            //Tracking updates or cancellations to alert work orders
            sales_order_cancelled: false,
            sales_order_updated: false
        }
    }
}
export default mixin_InitSalesOrder