<template>
  <v-container fluid>
    <v-row dense>
      <v-col lg="8" offset-lg="2">
        <v-card-title>
          <v-btn to="/sales-orders" color="#ffffff" light elevation="0">
            <v-icon left large>mdi-chevron-left</v-icon>
          </v-btn>
          <h3>{{ proFormaTitle }} Summary</h3>
          <v-spacer></v-spacer>
          <!--Generate PDF Button -->
          <v-menu
            bottom
            open-on-hover
            :offset-y="offset"
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="2"
                color="#e7e7f7"
                light
                class="ml-10"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>mdi-file-pdf</v-icon>
                Generate PDF
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list
              v-for="doc in [
                { name: 'Quotation' },
                { name: 'Pro-Forma Invoice' },
              ]"
              :key="doc.name"
            >
              <!--Sales order PDF button-->
              <v-list-item link @click="generateSalesPDF(doc.name)">
                <v-icon left>mdi-file-pdf</v-icon>
                <v-list-item-content>{{ doc.name }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <!--End of generate Button-->
        </v-card-title>
        <v-sheet elevation="2" class="pa-5">
          <!--New Sales Order form -->
          <v-container>
            <!--Document title-->
            <v-row>
              <v-col>
                <h1>{{ proFormaTitle }} {{ order_number }}</h1>
              </v-col>
            </v-row>
            <!--Company Details-->
            <v-row>
              <v-col sm="3" md="3">
                <v-img :src="company_logo" max-width="150"></v-img>
              </v-col>
              <v-spacer></v-spacer>
              <v-col sm="3" md="3" class="text-right caption">
                <v-col>
                  {{ company_legal_name }}<br />
                  <span v-if="company_trading_as != null"
                    >T/A {{ company_trading_as }}</span
                  ><br />
                  Reg No. {{ company_reg_number }}<br />
                  VAT No. {{ company_VAT_number }}
                </v-col>
                <v-col>
                  {{ company_physical_address_line_1 }}<br />
                  {{ company_physical_address_line_2 }}<br />
                  {{ company_physical_address_city }}
                  {{ company_physical_address_zip }}<br />
                </v-col>
              </v-col>
            </v-row>
            <!--Divider-->
            <v-row class="my-3">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <!--Job details-->
            <v-row>
              <v-col sm="3" md="3">
                <h4>Order #</h4>
              </v-col>
              <v-col
                sm="3"
                md="3"
                v-if="!!quote_ref && order_status !== 'Quote'"
              >
                <h4>Quote Reference #</h4>
              </v-col>
              <v-col sm="3" md="3">
                <h4>Created By</h4>
              </v-col>
              <v-col sm="3" md="3">
                <h4>Date Created</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="3" md="3">
                {{ order_number }}
              </v-col>
              <v-col
                sm="3"
                md="3"
                v-if="!!quote_ref && order_status !== 'Quote'"
              >
                {{ quote_ref }}
              </v-col>
              <v-col sm="3" md="3">
                {{ sales_order_created_by_name }}
              </v-col>
              <v-col sm="3" md="3">
                {{ order_date }}
              </v-col>
            </v-row>
            <!--Divider-->
            <v-row class="my-3">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="3"
                md="3"
                v-if="work_order_production_status != 'Not Started'"
              >
                <h4>Work Order</h4>
              </v-col>
              <v-col sm="3" md="3" v-if="delivery_status != 'Not Started'">
                <h4>Delivery Note</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="3"
                md="3"
                v-if="work_order_production_status != 'Not Started'"
              >
                <router-link
                  :to="{
                    name: 'view-work-order',
                    params: { work_order_id: work_order_id },
                  }"
                >
                  {{ work_order_number }}
                </router-link>
              </v-col>
              <v-col sm="3" md="3" v-if="delivery_status != 'Not Started'">
                <router-link
                  :to="{
                    name: 'view-delivery-note',
                    params: { delivery_id: delivery_id },
                  }"
                >
                  {{ delivery_note_number }}
                </router-link>
              </v-col>
            </v-row>
            <!--Divider-->
            <v-row class="my-3">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <!--Customer Info-->
            <v-row>
              <v-col sm="6" md="6">
                <h4>Customer</h4>
              </v-col>
              <v-col sm="6" md="6">
                <h4>Delivery Address</h4>
              </v-col>
            </v-row>
            <v-row>
              <!--Display for individual customers-->
              <v-col
                sm="6"
                md="6"
                v-if="this.customer.customer_type === 'customer_individual'"
              >
                <a @click="viewCustomer(customer)">{{
                  customer.customer_name
                }}</a
                ><br />
                {{ customer.customer_cell }}<br />
                {{ customer.customer_email }}<br />
              </v-col>
              <!--Display for company customers-->
              <v-col sm="6" md="6" v-else>
                <a @click="viewCustomer(customer)">{{
                  customer.customer_name
                }}</a
                ><br />
                <span v-if="customer.customer_VAT_number != null">
                  VAT: {{ customer.customer_VAT_number }} </span
                ><br />
                <span v-if="customer.customer_contact_person_cell != null">
                  Tel: {{ customer.customer_contact_person_cell }}<br />
                </span>
              </v-col>
              <v-col sm="6" md="6">
                {{ customer.customer_addr_1 }}<br />
                {{ customer.customer_addr_2 }}<br />
                {{ customer.customer_city }} {{ customer.customer_zip }}<br />
              </v-col>
            </v-row>

            <!--Divider-->
            <v-row class="my-5">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <!--Line items-->
            <v-row>
              <v-col>
                <h3>Line Items</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4" md="4">
                <h4>Item Name</h4>
              </v-col>
              <v-col sm="2" md="2" class="text-center">
                <h4>Qty</h4>
              </v-col>
              <v-col sm="3" md="3" class="text-right">
                <h4>Price</h4>
              </v-col>
              <v-col sm="3" md="3" class="text-right">
                <h4>Amount</h4>
              </v-col>
            </v-row>
            <v-row v-for="item in line_items" :key="item.product_name">
              <v-col sm="4" md="4">
                {{ item.product_name }}
              </v-col>
              <v-col sm="2" md="2" class="text-center">
                {{ item.item_qty }}
              </v-col>
              <v-col sm="3" md="3" class="text-right">
                {{ formatAsCurrency("R", item.product_price) }}
              </v-col>
              <v-col sm="3" md="3" class="text-right">
                {{ formatAsCurrency("R", Number(item.line_item_amount)) }}
              </v-col>
            </v-row>
            <!--Totals-->
            <v-row class="my-5">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="3"
                md="3"
                offset-sm="6"
                offset-md="6"
                class="text-right"
              >
                Subtotal
              </v-col>
              <v-col sm="3" md="3" class="text-right">
                {{ formatAsCurrency("R", order_subtotal) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="3"
                md="3"
                offset-sm="6"
                offset-md="6"
                class="text-right"
              >
                Discount
              </v-col>
              <v-col sm="3" md="3" class="text-right">
                {{ formatAsCurrency("R", order_discount) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="3"
                md="3"
                offset-sm="6"
                offset-md="6"
                class="text-right"
              >
                Discounted Subtotal
              </v-col>
              <v-col sm="3" md="3" class="text-right">
                {{ formatAsCurrency("R", order_discounted_subtotal) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="3"
                md="3"
                offset-sm="6"
                offset-md="6"
                class="text-right"
              >
                Delivery Fee
              </v-col>
              <v-col sm="3" md="3" class="text-right">
                {{ formatAsCurrency("R", order_delivery_fee) }}
              </v-col>
            </v-row>
            <div v-show="this.company_is_vattable === true">
              <v-row>
                <v-col
                  sm="3"
                  md="3"
                  offset-sm="6"
                  offset-md="6"
                  class="text-right"
                >
                  Total (Ex VAT)
                </v-col>
                <v-col sm="3" md="3" class="text-right">
                  {{ formatAsCurrency("R", order_total_ex_VAT) }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  sm="3"
                  md="3"
                  offset-sm="6"
                  offset-md="6"
                  class="text-right"
                >
                  VAT Amount
                </v-col>
                <v-col sm="3" md="3" class="text-right">
                  {{ formatAsCurrency("R", order_VAT_amount) }}
                </v-col>
              </v-row>
            </div>
            <v-row class="my-3">
              <v-col sm="6" md="6" offset-sm="6" offset-md="6">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="3"
                md="3"
                offset-sm="6"
                offset-md="6"
                class="text-right"
              >
                <span class="font-weight-bold">Total</span>
              </v-col>
              <v-col sm="3" md="3" class="text-right">
                <span class="font-weight-bold">
                  {{ formatAsCurrency("R", order_value) }}
                </span>
              </v-col>
            </v-row>

            <!--Payments Section-->
            <ViewSalesOrdersPaymentSummary
              v-if="order_amount_paid !== 0"
              :customer_payments="customer_payments"
              :amount_paid="order_amount_paid"
              :value="{
                order_id: order_id,
                order_number: order_number,
                order_value: order_value,
                customer_payments: customer_payments,
              }"
            />

            <!--Divider-->
            <v-row class="my-3">
              <v-col sm="6" md="6" offset-sm="6" offset-md="6">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="3"
                md="3"
                offset-sm="6"
                offset-md="6"
                class="text-right"
              >
                <span class="font-weight-bold"> Amount Due </span>
              </v-col>
              <v-col sm="3" md="3" class="text-right">
                <span class="font-weight-bold">
                  {{ formatAsCurrency("R", order_amount_due) }}
                </span>
              </v-col>
            </v-row>

            <!--Edit button-->
            <v-row>
              <v-col>
                <v-card-actions
                  class="mt-3"
                  v-if="this.user_roles_list.flat().includes('sales_write')"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    text
                    color="#33cc99"
                    :to="{ name: 'edit-order', params: { order_id: order_id } }"
                  >
                    <v-icon left>mdi-pencil</v-icon>
                    Edit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import db from "../../../components/firebaseInit";
import store from "../store/store";
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import mixin_InitSalesOrder from "../mixins/mixin_InitSalesOrder";
import mixinCompanyProfile from "../../../globalActions/mixin_CompanyProfile";
import { formatAsCurrency } from "../../../composables/external";
import { generateSalesPDF } from "../data/external_sales_orders";
export default {
  components: {
    ViewSalesOrdersPaymentSummary: () =>
      import("../components/ViewSalesOrdersPaymentSummary.vue"),
  },
  name: "ViewSalesOrder",
  mixins: [mixin_UserRoles, mixinCompanyProfile, mixin_InitSalesOrder],
  data() {
    return {
      order_id: null,
      order_number: null,
      order_date: null,
      customer: null,
      //New jobs are set as 'Quotes' by default
      order_status: null,
      order_value: null,
      //Work orders
      work_order_number: null,
      work_order_id: null,
      work_order_production_status: null,
      //Line item related data
      //Products array is populated from database
      line_items: [],
      offset: null,
      delivery_id: null,
      delivery_note_number: null,
      delivery_status: null,
      sales_order_created_by_name: null,
      order_discount: 0,
      order_discounted_subtotal: 0,
      order_delivery_fee: 0,
      order_subtotal: 0,
      order_total_ex_VAT: 0,
      VAT_rate: 0,
      order_VAT_amount: 0,
      order_amount_paid: null,
      order_amount_due: null,
      customer_payment_allocation_id: null,
      quote_ref: null,
      customer_payments: null,
    };
  },
  //Database query that runs before the page loads
  beforeRouteEnter(to, from, next) {
    db.collection("sales_orders")
      .where("order_id", "==", to.params.order_id)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //vm assigns all the values we want to put in the template
          next((vm) => {
            //Sets template values to those stored in the database
            vm.order_id = doc.data().order_id;
            vm.order_number = doc.data().order_number;
            vm.order_date = doc.data().order_date;
            vm.customer = doc.data().customer;
            vm.order_status = doc.data().order_status;
            vm.work_order_number = doc.data().work_order_number;
            vm.work_order_production_status =
              doc.data().work_order_production_status;
            vm.work_order_id = doc.data().work_order_id;
            vm.line_items = doc.data().line_items;
            vm.delivery_id = doc.data().delivery_id;
            vm.delivery_note_number = doc.data().delivery_note_number;
            vm.delivery_status = doc.data().delivery_status;
            vm.sales_order_created_by_name =
              doc.data().sales_order_created_by_name;
            vm.order_subtotal = doc.data().order_subtotal;
            vm.order_discount = doc.data().order_discount;
            vm.order_discounted_subtotal = doc.data().order_discounted_subtotal;
            vm.order_delivery_fee = doc.data().order_delivery_fee;
            vm.order_total_ex_VAT = doc.data().order_total_ex_VAT;
            vm.VAT_rate = doc.data().VAT_rate;
            vm.order_VAT_amount = doc.data().order_VAT_amount;
            vm.order_value = doc.data().order_value;
            vm.order_amount_paid = doc.data().order_amount_paid;
            vm.order_amount_due = doc.data().order_amount_due;
            vm.customer_payment_allocation_id =
              doc.data().customer_payment_allocation_id;
            vm.quote_ref = doc.data().quote_ref;
            vm.customer_payments = doc.data().customer_payments;
          });
        });
      });
  },
  watch: {
    $route: "fetchData",
  },
  created() {
    // Get company VAT status from Company Profile
    db.collection("company_profile")
      .doc("NVmvKiV1F7os4LCRljSC")
      .get()
      .then((snapshot) => {
        const isVattable = snapshot.data().company_is_vattable;
        //  If company is Vattable, set rate to 15%
        if (isVattable === true) {
          this.VAT_rate = 0.15;
          //  If compny isn't Vattable, set rate to 0%
        } else {
          this.VAT_rate = 0;
        }
      });
  },
  computed: {
    //This is where data is retrieved from the Vuex state
    viewSalesOrderFormHeader() {
      return store.state.viewSalesOrderFormHeader;
    },
    //Determine PDF title based on order status
    proFormaTitle() {
      return this.order_status === "Quote" ? "Quotation" : "Sales Order";
    },
    //Show 'Cancelled' stamp on job documents
    cancelStamp() {
      return this.order_status === "Cancelled" ? "CANCELLED" : "";
    },
    //Show 'Cancelled' stamp on job documents
    completedStamp() {
      return this.order_status === "Completed" ? "CANCELLED" : "";
    },
  },
  methods: {
    formatAsCurrency,
    generateSalesPDF,
    //Maps database data to template relative to the sales order selected in this route
    fetchData() {
      db.collection("sales_orders")
        .where("order_id", "==", this.$route.params.order_id)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.order_id = doc.data().order_id;
            this.order_number = doc.data().order_number;
            this.order_date = doc.data().order_date;
            this.customer = doc.data().customer;
            this.order_status = doc.data().order_status;
            this.work_order_number = doc.data().work_order_number;
            this.work_order_production_status =
              doc.data().work_order_production_status;
            this.work_order_id = doc.data().work_order_id;
            this.line_items = doc.data().line_items;
            this.delivery_id = doc.data().delivery_id;
            this.delivery_note_number = doc.data().delivery_note_number;
            this.delivery_status = doc.data().delivery_status;
            this.sales_order_created_by_name =
              doc.data().sales_order_created_by_name;
            this.order_subtotal = doc.data().order_subtotal;
            this.order_discount = doc.data().order_discount;
            this.order_discounted_subtotal =
              doc.data().order_discounted_subtotal;
            this.order_delivery_fee = doc.data().order_delivery_fee;
            this.VAT_rate = doc.data().VAT_rate;
            this.order_VAT_amount = doc.data().order_VAT_amount;
            this.order_total_ex_VAT = doc.data().order_total_ex_VAT;
            this.order_value = doc.data().order_value;
            this.order_amount_paid = doc.data().order_amount_paid;
            this.order_amount_due = doc.data().order_amount_due;
            this.customer_payment_allocation_id =
              doc.data().customer_payment_allocation_id;
            this.quote_ref = doc.data().quote_ref;
            this.customer_payments = doc.data().customer_payments;
          });
        });
    },
    // Click to view customer in new tab
    viewCustomer(customer) {
      const routeData = this.$router.resolve({
        path: `/view-customer/${customer.customer_id}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>