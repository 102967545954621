<template>
  <div>
    <v-container fluid class="px-10">
      <v-row class="mb-8">
        <v-col>
          <v-card-title>
            <h2>Sales Orders</h2>
            <!--Skeleton button-->
            <v-skeleton-loader
              :loading="loading_user"
              type="button"
              v-if="firstLoad"
              class="ml-10"
            >
            </v-skeleton-loader>
            <!--CTA button-->
            <ButtonCTA
              class="white-text"
              :button_name="addSalesOrderBtn.btn_add_sales_order"
              :button_to="addSalesOrderBtn.btn_new_sales_order_to"
              :button_color="primaryCTA.btn_primary_color"
              :button_icon="primaryCTA.btn_plus_icon"
              :button_margin_left="primaryCTA.btn_ml_10"
              v-if="!firstLoad"
              v-show="this.user_roles_list.flat().includes('sales_write')"
            />
            <v-spacer> </v-spacer>
            <!--More Actions Button-->
            <!--More Options Button-->
            <v-menu
              bottom
              open-on-hover
              :offset-y="offset"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined left v-bind="attrs" v-on="on">
                  More Actions
                  <v-icon right small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <SalesOrdersTableMoreActionsBtn
                :bulkActions="this.bulkActions"
                :selected_sales_orders="this.selected_sales_orders"
                @changeBulkActions="ChangeBulkActions($event)"
                @closeBulkActions="CloseBulkActions($event)"
                @triggerBulkWorkOrders="openBulkWorkOrdersModal($event)"
              />
            </v-menu>
            <!--Bulk Work Orders Dialog-->
            <BulkWorkOrdersDialog
              :bulk_work_orders_dialog="this.bulk_work_orders_dialog"
              :selected_sales_orders="this.selected_sales_orders"
              :work_orders_array="this.work_orders_array"
              :bulk_work_order_stepper="this.bulk_work_order_stepper"
              :loading_bulk_work_orders="this.loading_bulk_work_orders"
              @triggerBulkWorkOrders="
                createBulkWorkOrders($event),
                  getWorkOrderCount($event),
                  getCurrentUser($event)
              "
              @triggerBulkWorkOrdersClose="closeBulkWorkOrdersDialog($event)"
              @triggerBulkWorkOrdersComplete="completeBulkWorkOrders($event)"
            />
          </v-card-title>
        </v-col>
      </v-row>
      <!--Card skeleton loader-->
      <v-row class="mb-3" v-if="isLoading">
        <v-col md="3" lg="3">
          <v-skeleton-loader
            :loading="loading_user"
            type="card"
            max-height="278"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <!--Dashboard summary tiles-->
      <sales-orders-table-summary-tiles
        v-else
        v-show="this.user_roles_list.flat().includes('sales_reporting')"
        :sales_orders="this.sales_orders"
        :dashboard_items="this.dashboard_items"
      >
      </sales-orders-table-summary-tiles>

      <!--Table filters-->
      <v-row class="mb-3">
        <v-col md="12" lg="12">
          <v-card class="px-5 pt-5" elevation="3">
            <v-row>
              <v-col md="2" sm="2">
                <h4>Status</h4>
              </v-col>
              <v-col md="2" sm="2">
                <h4>Search</h4>
              </v-col>
              <v-col md="4" sm="4">
                <h4>Date Range</h4>
              </v-col>
              <v-col md="2" sm="2">
                <h4>Production</h4>
              </v-col>
              <v-col md="2" sm="2">
                <h4>More Filters</h4>
              </v-col>
            </v-row>
            <v-row>
              <!--Status Filter-->
              <v-col md="2" sm="2">
                <v-select
                  :items="['Quote', 'Confirmed', 'Completed', 'Cancelled']"
                  v-model="salesOrderStatus"
                  label="Order Status"
                  outlined
                  flat
                  solo
                  dense
                  append-icon="mdi-filter-variant"
                >
                </v-select>
              </v-col>
              <!--Search Bar-->
              <v-col md="2" sm="2">
                <v-text-field
                  v-model="search"
                  label="Search"
                  outlined
                  flat
                  solo
                  dense
                  clearable
                  append-icon="mdi-magnify"
                >
                </v-text-field>
              </v-col>
              <!--Date Range-->
              <!--Start Date Picker-->
              <v-col lg="2" md="2">
                <v-menu
                  ref="start_date_menu"
                  v-model="start_date_menu"
                  :close-on-content-click="true"
                  :return-value.sync="date"
                  transition="scale-transition"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="start_date"
                      label="Start Date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      color="#3d2cdd"
                      clearable
                      @click:clear="clearStartDate()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_date"
                    :max="end_date"
                    @change="assignStartDate()"
                    title="End Date"
                    color="#3d2cdd"
                    no-title
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="#ce2458"
                      @click="start_date_menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="#33cc99"
                      @click="$refs.start_date_menu.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!--End Date Picker-->
              <v-col lg="2" md="2">
                <v-menu
                  ref="end_date_menu"
                  v-model="end_date_menu"
                  :close-on-content-click="true"
                  :return-value.sync="date"
                  transition="scale-transition"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="end_date"
                      label="End Date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      clearable
                      @click:clear="clearEndDate()"
                      color="#3d2cdd"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_date"
                    :min="start_date"
                    no-title
                    @change="assignEndDate()"
                    title="End Date"
                    color="#3d2cdd"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="#ce2458" @click="end_date_menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="#33cc99" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!--Production Filters-->
              <v-col md="2" sm="2">
                <v-select
                  :items="this.production_items"
                  v-model="productionStatus"
                  label="Production Status"
                  outlined
                  flat
                  solo
                  dense
                  append-icon="mdi-filter-variant"
                >
                </v-select>
              </v-col>
              <!--More Filters-->
              <v-col md="2" sm="2">
                <v-switch
                  v-model="more_filters"
                  label="More Filters"
                  color="indigo"
                  @change="getCustomers(), getProducts(), getUsers()"
                ></v-switch>
              </v-col>
            </v-row>
            <!--More filters-->
            <div v-if="more_filters === true">
              <v-row class="pt-3">
                <v-col md="3" sm="3">
                  <h4>Branch</h4>
                </v-col>
                <v-col md="3" sm="3">
                  <h4>Customer Name</h4>
                </v-col>
                <v-col md="3" sm="3">
                  <h4>Payments</h4>
                </v-col>
                <v-col md="3" sm="3">
                  <h4>Product Selection</h4>
                </v-col>
              </v-row>
              <v-row>
                <!-- Branch -->
                 <v-col md="3" sm="3">
                  <v-autocomplete
                    :items="branchesWithUnassigned"
                    item-text="branch_name"
                    item-value="branch_value"
                    v-model="branchSelect"
                    label="Select Branch"
                    outlined
                    flat
                    solo
                    dense
                    append-icon="mdi-filter-variant"
                    clearable
                    @click:clear="branchSelect = ''"
                  >
                  </v-autocomplete>
                </v-col>
                <!--Customer Name-->
                <v-col md="3" sm="3">
                  <v-autocomplete
                    :items="this.customers"
                    item-text="customer_name"
                    item-value="customer_name"
                    v-model="customerSelect"
                    label="Select Customer"
                    outlined
                    flat
                    solo
                    dense
                    append-icon="mdi-filter-variant"
                  >
                    <template v-slot:append-outer>
                      <v-chip>{{ customerSelectCount }}</v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--Payments-->
                <v-col md="3" sm="3">
                  <v-select
                    v-model="paymentStatus"
                    :items="['Outstanding Payments', 'Fully Paid']"
                    label="Payment Status"
                    color="indigo"
                    outlined
                    flat
                    solo
                    dense
                  >
                  </v-select>
                </v-col>
                <!--Product Selection-->
                <v-col md="3" sm="3">
                  <v-autocomplete
                    :items="this.products"
                    item-text="product_name"
                    item-value="product_name"
                    v-model="productSelect"
                    label="Select Product"
                    outlined
                    flat
                    solo
                    dense
                    append-icon="mdi-filter-variant"
                  >
                    <template v-slot:append-outer>
                      <v-chip>{{ productSelectCount }}</v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row md="4" sm="4" class="pt-3">
                <v-col>
                  <h4>Created By</h4>
                </v-col>
                <v-col md="4" sm="4" class="pt-3">
                  <h4>Order Value</h4>
                </v-col>
                <v-col md="4" sm="4" class="pt-3">
                  <h4>Tags</h4>
                </v-col>
              </v-row>
              <!--Created By-->
              <v-row>
                <v-col md="4" sm="4">
                  <v-autocomplete
                    :items="this.users"
                    item-text="displayName"
                    item-value="displayName"
                    v-model="userSelect"
                    label="Select User"
                    outlined
                    flat
                    solo
                    dense
                    append-icon="mdi-filter-variant"
                  >
                    <template v-slot:append-outer>
                      <v-chip>{{ userSelectCount }}</v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--Order value slider-->
                <v-col md="4" sm="4">
                  <v-range-slider
                    v-model="orderValue"
                    :max="calcMaxValue"
                    hide-details=""
                    :min="0"
                    thumb-color="indigo lighten-4"
                    @change="triggerOrderRange = true"
                  >
                    <template v-slot:prepend>
                      <v-text-field
                        :value="orderValue[0]"
                        class="mt-0 pt-0"
                        persistent-hint
                        single-line
                        hint="Min Amount"
                        type="number"
                        style="width: 60px"
                        @change="
                          $set(orderValue, 0, $event),
                            (triggerOrderRange = true)
                        "
                      ></v-text-field>
                    </template>
                    <template v-slot:append>
                      <v-text-field
                        :value="orderValue[1]"
                        class="mt-0 pt-0"
                        persistent-hint
                        single-line
                        hint="Max Amount"
                        type="number"
                        style="width: 60px"
                        @change="
                          $set(orderValue, 1, $event),
                            (triggerOrderRange = true)
                        "
                      ></v-text-field>
                    </template>
                  </v-range-slider>
                </v-col>
                <!--Tag Selection-->
                <v-col md="4" sm="4">
                  <v-autocomplete
                    :items="this.tags"
                    item-text="name"
                    item-value="name"
                    v-model="tagSelect"
                    label="Select Tag"
                    outlined
                    flat
                    solo
                    dense
                    append-icon="mdi-filter-variant"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!--End of table filters-->

      <!--Table skeleton loader-->
      <v-row v-if="isLoading">
        <v-col>
          <v-sheet elevation="4">
            <v-skeleton-loader
              :loading="isLoading"
              type="table"
              max-height="290"
            >
            </v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
      <!--End of skeleton loader-->
      <v-row v-else>
        <v-col>
          <v-sheet elevation="4">
            <!--Table-->
            <v-data-table
              v-model="selected_sales_orders"
              item-key="order_id"
              :headers="salesOrderTableHeaders"
              fixed-header
              height="100vh"
              sort-by="order_number"
              :items="
                filterSalesStatus.map((obj) => ({
                  ...obj,
                  customer_name: obj.customer.customer_name,
                }))
              "
              :search="search"
              :items-per-page="250"
              :item-class="row_classes"
              group-by="order_date"
              :show-select="bulkActions"
              show-expand
              single-expand
              @item-expanded="fetchProductionPlanDetails"
            >
              <!--Group header-->
              <template v-slot:group.header="{ headers, items }">
                <th
                  :colspan="headers.length"
                  class="indigo lighten-5"
                  style="height: 36px"
                >
                  {{
                    new Date(items[0].order_date).toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  }}
                </th>
              </template>
              <!--Line item expansion dropdown-->
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="px-0">
                  <SalesOrderTableLineItemExpansion :item="item" />
                </td>
              </template>
              <!--Clickable link that routes from table to job preview page-->
              <template v-slot:item.order_number="{ item }">
                <router-link
                  style="text-decoration: none"
                  :to="{
                    name: 'view-order',
                    params: { order_id: item.order_id },
                  }"
                >
                  {{ item.order_number }}
                </router-link>
              </template>
              <template #item.order_value="{ item }">
                {{ formatAsCurrency("R", item.order_value) }}
              </template>

              <!--Customer Name-->
              <template v-slot:item.customer="{ item }">
                {{ item.customer.customer_name }}
              </template>

              <!-- Payment Status -->
              <template v-slot:item.full_payment_received="{ item }">
                  <!-- With paymentList feature -->
                  <span v-if="item.order_date >= featureFlags.paymentListFeature && item.payments" @click="customerPaymentsDialog = true, selectedOrder = item">
                      <div v-if="item.full_payment_received">
                          <v-chip color="green lighten-4" class="hover-pointer" small>Fully Paid</v-chip>
                      </div>
                      <div v-else>
                          <v-chip color="red lighten-4" class="hover-pointer" small>
                              Due: {{ formatAsCurrency("R", (item.order_value - item.amount_paid)) }}
                          </v-chip>
                      </div>
                  </span>
                  <!-- Without paymentList feature -->
                  <span v-else>
                      <div v-if="item.full_payment_received">
                          <v-chip color="green lighten-4" small>Fully Paid</v-chip>
                      </div>
                      <div v-else>
                          <v-chip color="red lighten-4" small>
                              Due: {{ formatAsCurrency("R", (item.order_value - item.amount_paid)) }}
                          </v-chip>
                      </div>
                  </span>
              </template>

               <!-- Production Status -->
               <template v-slot:item.production_status="{ item }">
                    <v-chip :color="getProductionStatusColor(item.production_status)" small>
                        {{ item.production_status }}
                    </v-chip>
                </template>


              <!--Invoice status-->
              <template v-slot:item.xeroInvoiceId="props">
                <v-chip
                  @click="getXeroInvoiceAsPdf(props.item.xeroInvoiceId, props.item.order_number)"
                  v-if="props.item.xeroInvoiceId"
                  :disabled="loadingStates[props.item.order_number]"
                  small>
                  <template v-if="loadingStates[props.item.order_number]">
                    Loading...
                    <v-progress-circular indeterminate color="white" size="18" class="ml-2"></v-progress-circular>
                  </template>
                  <template v-else>
                    {{ `INV-${props.item.order_number.split("-")[1].padStart(5,"0")}` }}
                    <v-icon right x-small>mdi-download</v-icon>
                  </template>
                </v-chip>
              </template>

              
              <!--Edit icon button-->
              <template
                v-slot:item.order_id="props"
                v-if="this.user_roles_list.flat().includes('sales_write')"
              >
                <v-btn
                  :to="{
                    name: 'edit-order',
                    params: { order_id: props.item.order_id },
                  }"
                  color="black"
                  icon
                  small
                  ><v-icon small>mdi-pencil</v-icon></v-btn
                >
                <!--Action Button-->
                <v-btn
                  icon
                  small
                  right
                  color="black"
                  @click.stop="
                    $set(viewSalesOrderMenu, props.item.order_id, true)
                  "
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
                <SalesOrdersTableOrderActionsMenu
                  :item="props.item"
                  :branches="branches"
                  :viewSalesOrderMenu="viewSalesOrderMenu"
                  :user_roles_list="user_roles_list"
                />
              </template>
              <!--Disable edit button based on user roles-->
              <template v-slot:props.item.order_id v-else>
                <v-btn color="#3d2cdd" icon small disabled
                  ><v-icon small>mdi-pencil-off</v-icon></v-btn
                >
              </template>
              <!--Empty datatable slot-->
              <template v-slot:no-data>
                <v-container>
                  <v-row>
                    <v-col>
                      <div class="my-3">
                        <h3>Welcome To Your Sales Dashboard</h3>
                        <p>
                          Here you can create, view, edit, and track all<br />
                          of your sales orders.
                        </p>
                        <v-btn color="#33cc99" dark to="/new-sales-order"
                          ><v-icon left>mdi-plus</v-icon>Add Order</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <!-- Customer Payments Dialog -->
    <v-dialog v-model="customerPaymentsDialog" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Customer Payments</v-card-title>
                    <v-list dense>
                        <!-- Payments list -->
                        <v-list-item
                            v-for="(payment, index) in selectedOrder.payments"
                            :key="payment.id"
                        >
                            <v-list-item-content>
                            <v-row align="center" no-gutters>
                                <v-col cols="1">{{ index + 1 }}.</v-col>
                                <v-col cols="4">{{ payment.date }}</v-col>
                                <v-col cols="3">{{ payment.type }}</v-col>
                                <v-col cols="3" class="text-right">{{ formatAsCurrency("R", payment.amount) }}</v-col>
                            </v-row>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="customerPaymentsDialog = false, selectedOrder = {}">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
  </div>
</template>

<script>
import db from "../../../components/firebaseInit"; //imports firebase database that was specified in firebaseInit.js
import firebase from "firebase";
import axios from "axios";
import store from "../store/store";
import { mapState } from "vuex";
import { featureFlags } from '../../../composables/featureFlags.js';
import { formatAsCurrency } from "../../../composables/external";
import {
  filterSalesStatus,
  calcMaxValue,
  setWorkOrderRoute,
  assignStartDate,
  assignEndDate,
  getColorWorkOrders,
  getWorkOrderIcon,
  getDarkOrLight,
  createBulkWorkOrders,
  openBulkWorkOrdersModal,
} from "../data/external_sales_orders";
import {
  getCustomers,
  getProducts,
  productSelectCount,
  customerSelectCount,
  getUsers,
  userSelectCount,
} from "../data/external_get_customers_products_users";
import mixin_tags from "../mixins/mixin_tags";

export default {
  components: {
    ButtonCTA: () => import("../../../components/Buttons/ButtonCTA"),
    SalesOrdersTableSummaryTiles: () =>
      import("../components/SalesOrdersTableSummaryTiles"),
    SalesOrdersTableMoreActionsBtn: () =>
      import("../components/SalesOrdersTableMoreActionsBtn"),
    BulkWorkOrdersDialog: () => import("../components/BulkWorkOrdersDialog"),
    SalesOrdersTableOrderActionsMenu: () =>
      import("../components/SalesOrdersTableOrderActionsMenu"),
    SalesOrderTableLineItemExpansion: () =>
      import("../components/SalesOrderTableLineItemExpansion"),
  },
  mixins: [mixin_tags],
  name: "SalesOrdersTable",
  data() {
    return {
      loadingStates: {},
      search: "",
      tab: null,
      isLoading: false,
      branches: [],
      items: ["Confirmed Jobs", "Quotes", "Cancelled", "Completed"],
      production_items: [
        "Not Started",
        "Work Order Created",
        "In Progress",
        "Completed",
        "On Hold",
        "Cancelled",
      ],
      delivery_status_items: [
        "Not Started",
        "Delivery Note Created",
        "Ready For Dispatch",
        "Dispatched",
        "Completed",
      ],
      singleSelect: false,
      selected: [],
      offset: null,
      //Import sales order data from firebase store
      sales_orders: [],
      salesOrderStatus: "Confirmed",
      productionStatus: "",
      branchSelect: "",
      customerSelect: "",
      paymentStatus: "",
      productSelect: "",
      tagSelect: "",
      products: [],
      userSelect: "",
      users: [],
      orderValue: [0, 0],
      triggerOrderRange: false,
      customers: [],
      dialog: false,
      dashboard_items: [
        {
          name: "Confirmed Orders",
          item_order_status: "Confirmed",
          color: "#88e2b7",
        },
      ],
      date: null,
      dateFilter: ["2020-10-01", new Date().toISOString().slice(0, 10)],
      start_date: null,
      end_date: null,
      start_date_menu: null,
      end_date_menu: null,
      more_filters: false,
      bulkActions: false,
      selected_sales_orders: [],
      bulk_work_orders_dialog: false,
      work_order_count: null,
      work_order_created_by_id: null,
      work_order_created_by_name: null,
      bulk_work_order_stepper: 1,
      work_orders_array: [],
      loading_bulk_work_orders: false,
      viewSalesOrderMenu: {},
      user_roles_list: [],
      loading_user: false,
      firstLoad: true,
      //
      featureFlags,
      customerPaymentsDialog: false,
      selectedOrder: {},
    };
  },
  store,
  computed: mapState({
    //This is where data is retrieved from the Vuex state
    primaryCTA: (state) => state.primaryCTA,
    addSalesOrderBtn: (state) => state.addSalesOrderBtn,
    salesOrderTableHeaders: (state) => state.salesOrderTableHeaders,
    calcMaxValue,
    productSelectCount,
    customerSelectCount,
    userSelectCount,
    filterSalesStatus,
    branchesWithUnassigned() {
      return [
            { branch_name: 'Unassigned', branch_value: undefined },
            ...this.branches.map((e) => ({ branch_name: e.branch_name, branch_value: e.branch_value  }))
        ];
    }
  }),
  created() {
    // Get branches from database
    this.getBranches();
    //
    this.getSalesOrders();
    //Get the user id of the current user
    var user_id = firebase.auth().currentUser.uid;
    (this.loading_user = true), (this.firstLoad = true);
    // Get current user's roles
    db.collection("users")
      .where("uid", "==", user_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const user_roles = doc.data().roles;
          // Send user roles to array
          this.user_roles_list.push(user_roles);
          this.firstLoad = false;
          this.loading_user = false;
        });
      });
  },
  updated() {
    this.getWorkOrderCount();
    this.getCurrentUser();
  },
  methods: {
    formatAsCurrency,
    getCustomers,
    getProducts,
    getUsers,
    setWorkOrderRoute,
    assignStartDate,
    assignEndDate,
    getColorWorkOrders,
    getWorkOrderIcon,
    getDarkOrLight,
    openBulkWorkOrdersModal,
    createBulkWorkOrders,
    ChangeBulkActions(bulkActions) {
      this.bulkActions = bulkActions;
    },
    CloseBulkActions(bulkActions, selected_sales_orders) {
      this.bulkActions = bulkActions;
      console.log("selected_sales_orders", selected_sales_orders);
      this.selected_sales_orders = selected_sales_orders;
    },
    closeBulkWorkOrdersDialog(
      bulk_work_orders_dialog,
      selected_sales_orders,
      bulk_work_order_stepper
    ) {
      this.bulk_work_orders_dialog = bulk_work_orders_dialog;
      this.selected_sales_orders = selected_sales_orders;
      this.bulk_work_order_stepper = bulk_work_order_stepper;
    },
    completeBulkWorkOrders() {
      this.$router.go();
    },
    getWorkOrderCount() {
      db.collection("work_order_number_counter")
        .doc("ruQc3stD9jaDZpBEJ5wG")
        .get()
        .then((snapshot) => {
          this.work_order_count = snapshot.data().work_order_count;
        });
    },
    getCurrentUser() {
      this.work_order_created_by_id = firebase.auth().currentUser.uid;
      db.collection("users")
        .where("uid", "==", this.work_order_created_by_id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.work_order_created_by_name = doc.data().displayName;
          });
        });
    },
    formatPayment(payment) {
      return payment === 0 ? "red--text" : "green--text";
    },
    //Format table row colours based on sales activities
    row_classes(item) {
      return item.work_order_production_status === "Completed" &&
        item.delivery_status === "Completed"
        ? "green lighten-4"
        : "white";
    },
    //
    resetValue() {
      this.productSelect = "";
    },
    //
    getBranches() {
      db.collection("branches").orderBy("branch_name").onSnapshot((querySnapshot) => {
        this.branches = [];
        this.branches = querySnapshot.docs.map((doc) => doc.data());
    });
    },
    // 
    getSalesOrders() {
      this.isLoading = true; // Set loading state to true before fetching data
      const docRef = db.collection("sales_orders");

      try {
        docRef.onSnapshot((querySnapshot) => {
          this.sales_orders = querySnapshot.docs.map((doc) => {
            const order = { ...doc.data() };

            order.production_status = this.calculateProductionStatus(order.line_items);

            const enablePaymentListFeature = order.order_date >= this.featureFlags.paymentListFeature && order.payments;
            // Payment status calculations   
            order.amount_paid = enablePaymentListFeature
              ? order.payments.reduce((sum, payment) => sum + Number(payment.amount), 0)
              : order.deposit_recieved + order.balance_recieved
                  
            order.full_payment_received = order.order_value === order.amount_paid;
            
            this.isLoading = false;
            return order;
          });
        });
      } catch (error) {
        console.error("Error fetching sales orders:", error);
        this.isLoading = false;
      }
    },
    //
    calculateProductionStatus(lineItems) {
        const totalItems = lineItems.length;
        const assignedItems = lineItems.filter(item => item.assigned_to_plan).length;
        return `${assignedItems} / ${totalItems} Items Assigned`;
    },
    getProductionStatusColor(status) {
        const [assigned, total] = status.split(' / ').map(Number);
        if (assigned === total) {
            return 'green lighten-4';
        } else if (assigned > 0) {
            return 'amber lighten-4';
        } else {
            return 'red lighten-4';
        }
    },
    fetchProductionPlanDetails({ item, value }) {
        if (!value) return;
        // Loop through the line_items of the expanded order
        const { order_id, line_items } = item;

        const orderIndex = this.sales_orders.findIndex((order) => order.order_id === order_id);

        line_items.forEach(async ({ assigned_to_plan, plan_number }, index) => {
            if (!assigned_to_plan || plan_number) return;
            // Set loading state to true
            this.$set(this.sales_orders[orderIndex].line_items[index], 'loading', true);

            // Fetch production plan details from the database
            const planRef = db.collection("production_plans").doc(assigned_to_plan);
            try {
                // Set up a real-time listener for the production plan
                planRef.onSnapshot((doc) => {
                    if (doc.exists) {
                        this.$set(this.sales_orders[orderIndex].line_items[index], 'plan_number', doc.data().plan_number);
                        this.$set(this.sales_orders[orderIndex].line_items[index], 'production_completed', doc.data().plan_status === "Completed" ? "Yes" : "No");
                    }
                });
            } catch(error) {
                console.error("Error fetching production plan details:", error);
            } finally {
                // Set loading state to false
                this.$set(this.sales_orders[orderIndex].line_items[index], 'loading', false);
            }
        });
    },
    //
    async getXeroInvoiceAsPdf(invoiceId, orderNumber) {
      if (!invoiceId) return;
      // Set the loading state for this specific order
      this.$set(this.loadingStates, orderNumber, true);
      try {
        const response = await axios({
          method: 'get',
          url: `https://us-central1-vue-order-fulfillment.cloudfunctions.net/getXeroInvoiceAsPdf?invoiceId=${invoiceId}`,
          responseType: 'blob',
        });
        // Create and download the file
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', `Invoice-${orderNumber.padStart(5, "0")}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading invoice PDF:', error);
        this.$emit('error', 'Failed to download invoice PDF');
      } finally {
        // Reset the loading state
        this.$set(this.loadingStates, orderNumber, false);
      }
    },
  },
};
</script>

<style scoped>
  .white-text {
    color: #fff;
  }
  .hover-pointer:hover {
      cursor: pointer;
  }
</style>