const mixin_tags = {
    data() {
        return {
            tags: [
                {
                    name: "High Priority",
                    icon: "mdi-priority-high",
                    toggled: false,
                },
                { name: "Low Priority", icon: "mdi-priority-low", toggled: false },
                { name: "Lay-Bye", icon: "mdi-progress-clock", toggled: false },
                {
                    name: "Commission Owed",
                    icon: "mdi-account-cash",
                    toggled: false,
                },
                {
                    name: "Commission Paid",
                    icon: "mdi-cash-check",
                    toggled: false,
                },
                {
                    name: "Refund Due",
                    icon: "mdi-cash-refund",
                    toggled: false,
                },
                { name: "Custom Items", icon: "mdi-tools", toggled: false },
                {
                    name: "Client Uncontactable",
                    icon: "mdi-phone-remove",
                    toggled: false,
                },
                {
                    name: "Client Unlikely To Pay",
                    icon: "mdi-cash-remove",
                    toggled: false,
                },
                {
                    name: "Material Shortage",
                    icon: "mdi-warehouse",
                    toggled: false,
                },
                {
                    name: "Client Likely To Leave Positive Review",
                    icon: "mdi-emoticon-happy",
                    toggled: false,
                },
                {
                    name: "Delivered With Payment Pending",
                    icon: "mdi-truck",
                    toggled: false,
                },
            ],
        }
    }
}

export default mixin_tags