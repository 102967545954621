<template>
  <v-container fluid>
    <v-row dense>
      <v-col lg="10" offset-lg="1" sm="12" md="12">
        <FormHeader v-bind="newSalesOrderFormHeader" />
        <v-card>
          <!--New Sales Order form -->
          <v-form
            @submit.prevent="saveSalesOrder"
            class="pa-3"
            ref="form"
            v-model="valid"
          >
            <v-container>
              <!-- Branch -->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                      v-model="order_branch"
                      :items="branches"
                      :rules="[RequireFormInput('branch')]"
                      item-text="branch_name"
                      return-object
                      cache-items
                      label="Branch"
                      outlined
                      dense
                      color="#3d2cdd"
                      class="font-weight-bold"
                      :menu-props="{ closeOnClick: true }"
                      required
                  />
                </v-col>
              </v-row>
              <!--Job details-->
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <h4>Job Details</h4>
                </v-col>
              </v-row>
              <v-row>
                <!--Quote Number-->
                <QuoteNumberInput
                  v-if="
                    order_status === 'Quote' ||
                    (order_status === 'Confirmed' && !quote_ref) ||
                    (order_status === 'Completed' && !quote_ref)
                  "
                  :value="orderQuoteNumber()"
                  :loading="loading"
                />
                <!--Order Number-->
                <OrderNumberInput
                  v-else-if="
                    (order_status === 'Confirmed' && !!quote_ref) ||
                    (order_status === 'Completed' && !!quote_ref)
                  "
                  :value="orderQuoteNumber()"
                  :quote_ref="quote_ref"
                  :loading="loading"
                />

                <!--Date Picker-->
                <v-col cols="12" sm="6" md="6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="true"
                    :return-value.sync="date"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="order_date"
                        label="Date"
                        append-icon="mdi-calendar"
                        :rules="[RequireFormInput('date')]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        clearable
                        color="#3d2cdd"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="order_date"
                      @change="setVATRate()"
                      no-title
                      scrollable
                      color="#3d2cdd"
                      show-adjacent-months
                      :allowed-dates="
                        (date) => date <= new Date().toISOString().substr(0, 10)
                      "
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="#ce2458" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="#33cc99"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <!--Customers-->
                <v-col cols="12" sm="6" md="6">
                  <!--populate with customer names from database-->
                  <v-autocomplete
                    v-model="customer"
                    :rules="[RequireFormInput('customer')]"
                    :items="customers"
                    item-text="customer_name"
                    return-object
                    cache-items
                    label="Customer"
                    outlined
                    dense
                    color="#3d2cdd"
                    class="font-weight-bold"
                    :menu-props="{ closeOnClick: true }"
                  >
                    <!--Add Customer Button-->
                    <template
                      v-slot:prepend-item
                      v-if="
                        this.user_roles_list.flat().includes('customers_write')
                      "
                    >
                      <v-list-item ripple>
                        <v-dialog v-model="newCustomerDialog" width="850">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text color="#3dcb9a" v-bind="attrs" v-on="on"
                              ><v-icon small left>mdi-plus</v-icon> Add New
                              Customer</v-btn
                            >
                          </template>
                          <NewCustomerModal
                            @closeDialog="closeCustomerDialog($event)"
                            @passCustomerDetails="setCustomerDetails($event)"
                          />
                        </v-dialog> </v-list-item
                    ></template>
                    <!--Format list items-->
                    <template v-slot:selection="data" trim>
                      {{ data.item.customer_name }}
                    </template>
                    <!--Dropdown items-->
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.customer_name }}</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          <span class="mr-1">{{
                            data.item.customer_email
                          }}</span></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </template>
                    <!--A button that lets users add new customers if not in select list
                                    Reroute to Add Customer interface-->
                    <template
                      v-if="
                        this.user_roles_list.flat().includes('customers_write')
                      "
                    >
                      <v-list-item slot="append-item">
                        <v-btn
                          text
                          color="#3d2cdd"
                          to="/new-customer-individual"
                          ><v-icon>mdi-plus</v-icon> Add New Customer</v-btn
                        >
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--Order Status-->
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="order_status"
                    required
                    :items="order_status_items"
                    label="Order Status"
                    color="#3d2cdd"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <!--Discount and delivery-->
              <v-row>
                <v-col sm="6" md="6">
                  <v-text-field
                    v-model.number="discountRate"
                    prefix="%"
                    min="0"
                    max="100"
                    step="0.01"
                    type="number"
                    label="Discount"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
                <v-col v-if="order_date < featureFlags.fulfillmentMethodFeaure" sm="6" md="6">
                  <v-text-field
                    v-model.number="order_delivery_fee"
                    prefix="R"
                    min="0"
                    step="0.01"
                    type="number"
                    label="Delivery Fee"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- Fulfillment Method Feature -->
              <span v-if="order_date >= featureFlags.fulfillmentMethodFeaure">
                <FulfillmentMethod v-model="fulfillment_data" :collectionLocations="branches" />
              </span>

              <!-- Payments List Feature-->
              <span v-if="order_status !== 'Quote'">
                  <PaymentList
                    v-if="order_date >= featureFlags.paymentListFeature"
                    :payments.sync="payments"
                    :salesOrder="{
                      order_id,
                      order_date,
                      customer,
                      order_value
                    }"
                  />

                  <!-- Deposit and Balance Feature-->
                  <span v-else>
                    <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <h4>Payments</h4>
                    </v-col>
                  </v-row>
                  <!--Deposits-->
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model.number="deposit_recieved"
                        label="Deposit Received"
                        color="#3d2cdd"
                        outlined
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <!--Balance-->
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model.number="balance_recieved"
                        label="Balance Received"
                        color="#3d2cdd"
                        outlined
                        dense
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  </span>
            </span>

              <!--Line Items-->
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  lg="11"
                  sm="11"
                  md="11"
                  offset-lg="1"
                  offset-md="1"
                  offset-sm="1"
                >
                  <h3 class="mt-5">Line Items</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="5" md="5" offset-lg="1" offset-md="1" offset-sm="1">
                  <h4>Item</h4>
                </v-col>
                <v-col sm="1" md="1">
                  <h4>Qty</h4>
                </v-col>
                <v-col sm="2" md="2">
                  <h4>Unit Price</h4>
                </v-col>
                <v-col sm="3" md="3">
                  <h4>Amount (R)</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="12">
                  <draggable
                    :list="line_items"
                    group="line_items"
                    :options="{ handle: '.dragg-me' }"
                  >
                    <transition-group>
                      <v-form v-for="(item, index) in line_items" :key="index">
                        <v-container
                          @mouseover="hover = true"
                          @mouseleave="hover = false"
                        >
                          <v-row desne>
                            <!--Drag handle-->
                            <v-col sm="1" md="1">
                              <v-btn
                                class="dragg-me"
                                v-if="hover"
                                icon
                                large
                                color="#141442"
                              >
                                <v-icon>mdi-drag</v-icon>
                              </v-btn>
                            </v-col>

                            <!--Product dropdown-->
                            <v-col
                              :sm="item.hasVariants ? '3' : '5'"
                              :md="item.hasVariants ? '3' : '5'"
                              >
                              <v-autocomplete
                                v-model="item.product"
                                :items="products"
                                item-text="product_name"
                                return-object
                                placeholder="Choose a product..."
                                :rules="[RequireFormInput('product')]"
                                cache-items
                                required
                                outlined
                                dense
                                color="#3d2cdd"
                                trim
                                @change="setLineItemData(item.product, index)"
                              >
                                <!--Product Selection-->
                                <template v-slot:selection="data" trim>
                                  {{ data.item.product_name }}
                                </template>
                                <!--Dropdown items-->
                                <template v-slot:item="data">
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-html="data.item.product_name"
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                      v-html="data.item.product_code"
                                    ></v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                                <!--A button that lets users add new products if not in select list
                                                    Reroute to Add Product interface-->
                                <template>
                                  <v-list-item slot="append-item">
                                    <v-btn
                                      text
                                      color="#3d2cdd"
                                      to="/new-product"
                                      ><v-icon>mdi-plus</v-icon> Add New
                                      Product</v-btn
                                    >
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                              <!--end of product dropdown-->
                            </v-col>
                            <!-- Variants -->
                            <v-col v-if="item.hasVariants" sm="2" md="2">
                              <v-autocomplete
                                  v-model="item.variant"
                                  :items="item.variantList"
                                  :rules="[RequireFormInput('variant')]"
                                  :item-text="variant => `${variant.variant_set_name} - ${variant.variant_name}`"
                                  return-object
                                  cache-items
                                  :label="item.variant ? item.variant.variant_set_name : 'Variant'"
                                  outlined
                                  dense
                                  color="#3d2cdd"
                                  class="font-weight-bold"
                                  :menu-props="{ closeOnClick: true }"
                              >
                                <!-- Selected variant display -->
                                <template v-slot:selection="data">
                                    {{ data.item.variant_name }}
                                </template>
                                <!-- Dropdown items -->
                                <template v-slot:item="data">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ `${data.item.variant_set_name} - ${data.item.variant_name}` }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col sm="1" md="1">
                              <v-text-field
                                v-model.number="item.item_qty"
                                min="0"
                                step="1"
                                placeholder="QTY"
                                type="number"
                                outlined
                                dense
                                color="#3d2cdd"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col sm="2" md="2">
                              <v-text-field
                                v-model.number="item.product_price"
                                prefix="R"
                                min="0"
                                step="0.01"
                                type="number"
                                placeholder="Price"
                                outlined
                                dense
                                color="#3d2cdd"
                              >
                              <template v-slot:append v-if="item.catalogue_price">
                                  <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                          <v-btn
                                              icon
                                              v-bind="attrs"
                                              v-on="on"
                                              color="primary"
                                              @click="setPriceToCataloguePrice(item, index)"
                                          >
                                            <v-icon>mdi-information</v-icon>
                                          </v-btn>
                                      </template>
                                      <span>Click to set the price to the catalogue price</span>
                                  </v-tooltip>
                              </template>
                              </v-text-field>
                            </v-col>

                            <!--Line amount total-->
                            <v-col sm="2" md="2">
                              <v-text-field
                                v-bind:value="lineItemSubTotal[index]"
                                type="number"
                                prefix="R"
                                outlined
                                readonly
                                dense
                                color="#3d2cdd"
                              >
                              </v-text-field>
                            </v-col>
                            <!--Menu options Button-->
                            <v-col sm="1" md="1">
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                  </v-btn>
                                </template>
                                <!--Menu items-->
                                <v-list>
                                  <!--Add comment-->
                                  <v-list-item
                                    @click="
                                      item.show_comment_field =
                                        !item.show_comment_field
                                    "
                                  >
                                    <v-list-item-title>
                                      <v-icon left small
                                        >mdi-comment-outline</v-icon
                                      >
                                      Add Comment
                                    </v-list-item-title>
                                  </v-list-item>
                                  <!--Delete-->
                                  <v-list-item
                                    @click="removeItem(index)"
                                    :disabled="deleteDisable"
                                  >
                                    <v-list-item-title>
                                      <v-icon left small
                                        >mdi-trash-can-outline</v-icon
                                      >
                                      Delete Item
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-col>
                          </v-row>

                          <!--Comment field-->
                          <v-row v-show="item.show_comment_field === true">
                            <v-col sm="10" md="10" offset-md="1" offset-sm="1">
                              <v-textarea
                                v-model="item.line_item_comment"
                                counter="250"
                                outlined
                                dense
                                label="Comments"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </transition-group>
                  </draggable>
                  <!--Add more items to order-->
                  <v-row>
                    <v-col offset-lg="1" offset-md="1" offset-sm="1">
                      <v-btn @click="addItems" color="#33cc99" dark large>
                        <v-icon left>mdi-plus</v-icon>
                        Add Item
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <!--Total breakdown-->
          <!--Total breakdown-->
          <v-container class="px-5">
            <v-row>
              <v-col sm="4" md="4" offset-md="8" offset-sm="8">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="2"
                md="2"
                offset-sm="8"
                offset-md="8"
                class="text-right"
              >
                Subtotal
              </v-col>
              <v-col sm="2" md="2" class="text-right">
                {{ calculateSubtotal | toCurrency }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="2"
                md="2"
                offset-sm="8"
                offset-md="8"
                class="text-right"
              >
                Discount
              </v-col>
              <v-col sm="2" md="2" class="text-right">
                {{ calculateDiscount | toCurrency }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="2"
                md="2"
                offset-sm="8"
                offset-md="8"
                class="text-right"
              >
                Discounted Subtotal
              </v-col>
              <v-col sm="2" md="2" class="text-right">
                {{ calculateDiscountedSubtotal | toCurrency }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="2"
                md="2"
                offset-sm="8"
                offset-md="8"
                class="text-right"
              >
                Delivery Fee
              </v-col>
              <v-col sm="2" md="2" class="text-right">
                {{ order_delivery_fee | toCurrency }}
              </v-col>
            </v-row>

            <div v-show="this.company_is_vattable === true">
              <v-row>
                <v-col
                  sm="2"
                  md="2"
                  offset-sm="8"
                  offset-md="8"
                  class="text-right"
                >
                  Total (Ex VAT)
                </v-col>
                <v-col sm="2" md="2" class="text-right">
                  {{ calculateTotalExVat | toCurrency }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  sm="2"
                  md="2"
                  offset-sm="8"
                  offset-md="8"
                  class="text-right"
                >
                  VAT Amount
                </v-col>
                <v-col sm="2" md="2" class="text-right">
                  {{ calculateVATamount | toCurrency }}
                </v-col>
              </v-row>
            </div>
            <v-row class="my-3">
              <v-col sm="4" md="4" offset-md="8" offset-sm="8">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                sm="2"
                md="2"
                offset-sm="8"
                offset-md="8"
                class="text-right"
              >
                <h3>Total</h3>
              </v-col>
              <v-col sm="2" md="2" class="text-right">
                <h3>{{ calculateTotal | toCurrency }}</h3>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn to="/sales-orders" color="#ce2458" text x-large>
              Cancel
              <v-icon right>mdi-close</v-icon>
            </v-btn>
            <v-btn
              @click="saveSalesOrder"
              color="#33cc99"
              text
              x-large
              :disabled="!valid"
              :loading="loading"
            >
              Submit
              <v-icon right>mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//Connect to database
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import store from "../store/store";
import { mapState } from "vuex";
import FormHeader from "../../../components/Forms/FormHeader";
import draggable from "vuedraggable";
//Mixins
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import mixin_CompanyProfile from "../../../globalActions/mixin_CompanyProfile";
import mixin_InitSalesOrder from "../mixins/mixin_InitSalesOrder";
import mixin_GetCustomersProducts from "../mixins/mixin_GetCustomersProducts";
import { showSnackbar } from "../../../globalActions/index";
import { setVATRate } from "../data/external_sales_orders";
// Feature flags
import { featureFlags } from "../../../composables/featureFlags.js";

export default {
  name: "NewSalesOrder",
  components: {
    FormHeader,
    draggable,
    NewCustomerModal: () => import("../components/NewCustomerModal.vue"),
    QuoteNumberInput: () => import("../components/Inputs/QuoteNumberInput.vue"),
    OrderNumberInput: () => import("../components/Inputs/OrderNumberInput.vue"),
    PaymentList: () => import("../components/PaymentList.vue"),
    FulfillmentMethod: () => import("../components/FulfillmentMethod.vue"),
  },
  mixins: [
    mixin_UserRoles,
    mixin_CompanyProfile,
    mixin_InitSalesOrder,
    mixin_GetCustomersProducts,
  ],

  data() {
    return {
      //New jobs are set as 'Quotes' by default
      customer: this.setCustomerFromParams(),
      order_branch: null,
      branches: [],
      order_status: this.setStatusFromParams(),
      order_number: null,
      order_value: 0,
      deposit_recieved: 0,
      balance_recieved: 0,
      work_order_production_status: "Not Started",
      delivery_status: "Not Started",
      sales_order_created_by_id: null,
      sales_order_created_by_name: null,
      line_items: [
        {
          product_id: null,
          product_name: "",
          item_qty: 0,
          qty_completed: 0,
          product_price: 0,
          line_item_amount: 0,
          show_comment_field: false,
          line_item_comment: "",
        },
      ],
      discountRate: 0,
      order_discount: 0,
      order_discounted_subtotal: 0,
      order_delivery_fee: 0,
      order_subtotal: 0,
      order_total_ex_VAT: 0,
      VAT_rate: 0,
      order_VAT_amount: 0,
      selected_tags: [],
      loading: false,
      newCustomerDialog: false,
      quote_ref: null,
      invoice_id: null,
      payments: [],
      fulfillment_data: {},
      //Defines rules for form validation --> passes properties and rules bound to input fields
      // Valid property has a v-bind to the submit button and toggles disabled state
      valid: false,

      // FEATURE FLAGS
      featureFlags,

      RequireFormInput(propertyType) {
        return (v) =>
          (v && v.length !== null) || `You must specify a ${propertyType}`;
      },
      minLength(propertyType, minLength) {
        return (v) =>
          (v && v.length >= minLength) ||
          `The ${propertyType} must be at least ${minLength} characters long`;
      },
      maxLength(propertyType, maxLength) {
        return (v) =>
          v.length <= maxLength ||
          `The ${propertyType} can't be more than ${maxLength} characters long`;
      },
    };
  },
  store,
  computed: mapState({
    //This is where data is retrieved from the Vuex state
    newSalesOrderFormHeader: (state) => state.newSalesOrderFormHeader,

    //Calculates line item total amount and assigns to variable to save to database
    lineItemSubTotal() {
      return this.line_items.map((item) => {
        return (item.line_item_amount = Number(
          item.item_qty * item.product_price
        ).toFixed(2));
      });
    },
    calculateSubtotal() {
      return this.line_items.reduce((total, item) => {
        return (this.order_subtotal =
          total + item.item_qty * item.product_price);
      }, 0);
    },

    calculateDiscount() {
      // Calculate discount value as a number, ensuring the result is still a float
      this.order_discount = parseFloat((this.order_subtotal * (this.discountRate / 100)).toFixed(2));
      return this.order_discount;
    },
    
    calculateDiscountedSubtotal() {
      return (this.order_discounted_subtotal =
        this.order_subtotal - this.order_discount);
    },
    calculateTotalExVat() {
      const amount = this.order_discounted_subtotal + this.order_delivery_fee;
      return (this.order_total_ex_VAT = amount / (1 + this.VAT_rate));
    },
    calculateTotal() {
      return (this.order_value =
        this.order_discounted_subtotal + this.order_delivery_fee);
    },
    calculateVATamount() {
      return (this.order_VAT_amount =
        this.order_value - this.order_total_ex_VAT);
    },
    quoteReference() {
      return (this.quote_ref =
        this.order_status === "Quote" ? this.order_number : null);
    },
  }),
  watch: {
    fulfillment_data: {
      deep: true,
      handler(newVal) {
        this.order_delivery_fee = newVal.price;
      }
    },
  },
  created() {
    // Get company VAT status from Company Profile
    this.setVATRate();

    //Get Order & Quote counters from database
    this.loading = true;
    db.collection("order_number_counter")
      .doc("VVr0gV8WMDdzHNwkKQSe")
      .onSnapshot((snapshot) => {
        // Gets corder and quote counter object from datatbase
        const document = snapshot.data();
        //2) Destructure object to get individual counter values
        const { order_count, quote_count } = document;
        //Assign counter values as variables
        this.order_count = `SO-${order_count}`;
        this.quote_count = `QU-${quote_count}`;
        this.loading = false;
      });
    //Captures user that created the sales order
    this.sales_order_created_by_id = firebase.auth().currentUser.uid;
    db.collection("users")
      .where("uid", "==", this.sales_order_created_by_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.sales_order_created_by_name = doc.data().displayName;
        });
    });

    // Get branch list from database
    this.getBranches();
  },
  mounted() {
    //Disable 'Cancelled' status dropdown options
    this.order_status_items[3].disabled = true;
  },
  methods: {
    setVATRate,
    // Sets the default customer when creating additional orders for an
    // order that has already been invoiced
    setCustomerFromParams() {
      return this.$route.query.customer ? this.$route.query.customer : null;
    },
    setStatusFromParams() {
      return this.$route.query.customer ? "Confirmed" : "Quote";
    },
    //Adds a new empty line item to the order
    addItems() {
      this.line_items.push({
        product_id: null,
        product_name: "",
        item_qty: 0,
        qty_completed: 0,
        product_price: 0,
        line_item_amount: 0,
        show_comment_field: false,
        line_item_comment: "",
      });
      this.deleteDisable = false;
    },
    //Deletes a specific line item from the order
    removeItem: function (index) {
      this.line_items.splice(index, 1);
      if (this.line_items.length === 1) {
        this.deleteDisable = true;
      }
    },
    //Determine Order or Quote prefix and format
    orderQuoteNumber() {
      if (this.order_status === "Quote") {
        return (this.order_number = this.quote_count);
      } else {
        return (this.order_number = this.order_count);
      }
    },

    saveSalesOrder() {
      //Form validation --> ensures required fields aren't blank before continuing further
      if (this.$refs.form.validate()) {
        this.loading = true;
        var newDocRef = db.collection("sales_orders").doc();
        newDocRef
          .set({
            //Populated by the v-model values in the form
            //NB: Sets the order_id == to the document id in database
            order_id: newDocRef.id,
            order_number: this.order_number,
            order_branch: this.order_branch,
            quote_ref: this.quoteReference,
            invoice_id: this.invoice_id,
            order_date: this.order_date,
            customer: this.customer,
            order_status: this.order_status,
            order_value: this.order_value,
            deposit_recieved: this.deposit_recieved,
            balance_recieved: this.balance_recieved,
            work_order_production_status: this.work_order_production_status,
            delivery_status: this.delivery_status,
            line_items: this.formatLineItemsForSubmission(this.line_items),
            order_subtotal: this.order_subtotal,
            discountRate: this.discountRate,
            order_discount: this.order_discount,
            order_discounted_subtotal: this.order_discounted_subtotal,
            order_delivery_fee: this.order_delivery_fee,
            order_total_ex_VAT: this.order_total_ex_VAT,
            VAT_rate: this.VAT_rate,
            order_VAT_amount: this.order_VAT_amount,
            sales_order_cancelled: false,
            sales_order_updated: false,
            order_date_created: new Date().toISOString().slice(0, 10),
            order_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            sales_order_created_by_id: this.sales_order_created_by_id,
            sales_order_created_by_name: this.sales_order_created_by_name,
            //  Initialise values for payment allocations
            order_amount_due: this.order_value,
            order_amount_paid: 0,
            selected_tags: this.selected_tags,
            payments: this.payments,
            fulfillment_data: this.fulfillment_data,
          })
          .then(() => {
            this.loading = false;
            const db = firebase.firestore();
            //Order number counter
            //A counter in a doc in firebase is incremented when a new order is generated
            //The latest number is then used as the sales order number
            const increment = firebase.firestore.FieldValue.increment(1);
            const orderNumberCount = db
              .collection("order_number_counter")
              .doc("VVr0gV8WMDdzHNwkKQSe");
            //Increments either the Quote or order counter depending on the job status
            if (this.order_status === "Quote") {
              orderNumberCount.update({ quote_count: increment });
            } else orderNumberCount.update({ order_count: increment });
            //Reroute to sales orders list after database is updated
            showSnackbar("New Sales Order added successfully");
            this.$router.push("/sales-orders");
          });
      }
    },
    // Set details for customers added from the modal
    setCustomerDetails(details) {
      this.customer = details;
    },
    closeCustomerDialog(value) {
      this.newCustomerDialog = value;
    },
    // Get branch list from database
    async getBranches() {
      const docRef = db.collection("branches").orderBy("branch_name");

      docRef.onSnapshot((snapshot) => {
          this.branches = [];
          this.branches = snapshot.docs.map(doc => doc.data());
      });
    },
    // Set line item defaul data
    async setLineItemData(product, index) {
      // Check if there was a previous product_price set
      const previousPrice = this.line_items[index].product_price;
      // Reset variant data
      
      this.$set(this.line_items[index], 'hasVariants', false);
      this.$set(this.line_items[index], 'variant', null);
      
      const { product_id, product_price, product_name, product_variants } = product;

      // Set product data
      this.$set(this.line_items[index], 'product_id', product_id);
      this.$set(this.line_items[index], 'product_name', product_name);
      this.$set(this.line_items[index], 'product_price', previousPrice ? previousPrice : product_price || 0);

      if (previousPrice !== product_price && previousPrice !== 0) {
        // Save the catalogue price so user can switch between prices if need be
        this.$set(this.line_items[index], 'catalogue_price', product_price);
      }
      
      // Check for variants
      if (product_variants.length === 0) return;

      this.$set(this.line_items[index], 'hasVariants', true);
      const variants = await this.getVariants(product_variants);

      this.$set(this.line_items[index], 'variantList', variants);
      
    },
    //
    setPriceToCataloguePrice(item, index){
      this.$set(this.line_items[index], 'product_price', item.catalogue_price);
      delete item.catalogue_price;
    },
    // Get variants from database
    async getVariants(product_variants) {
      // Extract variant_set_id from each product_variant
      const variantSetIds = product_variants.map(variant => variant.variant_set_id);

      // Get all variant_sets that match the ids in variantSetIds
      const snapshot = await db.collection("variant_sets")
          .where("variant_set_id", "in", variantSetIds)
          .get();

      // Reduce the query results to a single flat array of variants
      const variants = snapshot.docs.reduce((acc, doc) => {
          const variantSet = doc.data();
          // Add variant_set_name to each variant
          const variantsWithSetName = variantSet.variants.map(variant => ({
              ...variant,
              variant_set_name: variantSet.variant_set_name
          }));
          return acc.concat(variantsWithSetName);
      }, []);

      return variants;
    },
    formatLineItemsForSubmission(lineItems) {
      return lineItems.map(e => ({
        item_qty: e.item_qty,
        line_item_amount: e.line_item_amount,
        line_item_comment: e.line_item_comment,
        product_id: e.product_id,
        product_name: e.product_name,
        product_price: e.product_price,
        show_comment_field: e.show_comment_field,
        variant: e.variant,
        qty_completed: e.qty_completed,
      }))
      
    },
  },
};
</script>