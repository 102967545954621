//Initialise form values on New & Edit Sales Order views
import db from '../../../components/firebaseInit'

const mixin_GetCustomersProducts = {
  data() {
    return {
      loading_customer: false,
      loading_product: false
    }
  },
  created() {
    this.loading_customer = true
    this.loading_product = true
    db.collection('customers').where('customer_status', '==', 'Available').orderBy('customer_name').onSnapshot(querySnapshot => {
      this.customers = [];
      querySnapshot.forEach(doc => {
        const data = doc.data()
        //Pushes the customers name to the empty customers array in 'data' above
        this.customers.push(data)
        this.loading_customer = false

      })
    }),
      // Gets products from firebase datatbase
      db.collection('products').where('product_status', '==', 'Available').orderBy('product_name').get().then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          //Pushes the products name to the empty customers array in 'data' above
          this.products.push(data)
          this.loading_product = false
        })
      })
  }
}

export default mixin_GetCustomersProducts