//  These functions are all used to populate lists related to filters on the sales dahsboard
import db from '../../../components/firebaseInit'
//  Populate customer list for dashboard filter
export const getCustomers = function () {
  db.collection('customers').where('customer_status', '==', 'Available').orderBy('customer_name').get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const data = doc.data()
        this.customers.push(data)
      })
    })
    .catch(error => console.log(error))
}
//  Display count next to customer field on filter
export const customerSelectCount = function () {
  return this.filterSalesStatus.filter(order => order.customer.customer_name === this.customerSelect).length
}

//  Get products
export const getProducts = function () {
  db.collection('products').where('product_status', '==', 'Available').get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const data = doc.data()
        this.products.push(data)
      })
    })
    .catch(error => console.log(error))
}
//  Display the count of products next to the filter field
export const productSelectCount = function () {
  const matchingOrders = this.filterSalesStatus.filter(
    item => item.line_items.some(product => product.product_name === this.productSelect)
  ).map(e => e.line_items).flat()
  //  Remove objects from array by value
  const newArray = matchingOrders.filter(product => {
    return product.product_name === this.productSelect
  })
  return newArray.reduce(function (total, product) {
    return total + product.item_qty;
  }, 0)
}
//  Get Users
export const getUsers = function () {
  db.collection('users').get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const data = doc.data()
        this.users.push(data)
      })
    })
    .catch(error => console.log(error))
}
//  Display user orders count next to field on filter
export const userSelectCount = function () {
  return this.filterSalesStatus.filter(order => order.sales_order_created_by_name === this.userSelect).length
}